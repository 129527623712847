import { gql } from '@apollo/client';

export const CREATE_OBJECT = gql`
  mutation createObject($input: ObjectInput!) {
    createObject(data: $input) {
      id
      name
      type
      description
      attributes
      attachments {
        id
        label
        contentUrl
      }
      defaultAttachment {
        id
        label
      }
      geolocations {
        id
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_OBJECT = gql`
  mutation updateObject($id: ID!, $input: ObjectInput!) {
    updateObject(id: $id, data: $input) {
      id
      name
      type
      description
      attributes
      attachments {
        id
        label
        contentUrl
      }
      defaultAttachment {
        id
        label
      }
      geolocations {
        id
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_OBJECT = gql`
  mutation deleteObject($id: ID!) {
    deleteObject(id: $id)
  }
`;

export const ADD_ATTACHMENT = gql`
  mutation addObjectAttachment($objectId: ID!, $data: AttachmentInput!) {
    addObjectAttachment(objectId: $objectId, data: $data) {
      id
      label
      contentUrl
    }
  }
`;

export const REMOVE_ATTACHMENT = gql`
  mutation deleteObjectAttachment($id: ID!) {
    deleteObjectAttachment(id: $id)
  }
`;

export const SET_DEFAULT_ATTACHMENT = gql`
  mutation setDefaultAttachment($objectId: ID!, $attachmentId: ID!) {
    setObjectDefaultAttachment(objectId: $objectId, attachmentId: $attachmentId) {
      id
      defaultAttachment {
        id
        label
      }
    }
  }
`;

export const ADD_GEOLOCATION = gql`
  mutation addObjectGeolocation($objectId: ID!, $data: GeolocationInput!) {
    addObjectGeolocation(objectId: $objectId, data: $data) {
      id
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_GEOLOCATION = gql`
  mutation updateObjectGeolocation($id: ID!, $data: GeolocationInput!) {
    updateObjectGeolocation(id: $id, data: $data) {
      id
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_GEOLOCATION = gql`
  mutation deleteObjectGeolocation($id: ID!) {
    deleteObjectGeolocation(id: $id)
  }
`;
