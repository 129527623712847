import React from 'react';
import moment from 'moment';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Table, Popconfirm, Button, Input, notification, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQueryParam, withDefault, StringParam, NumberParam, JsonParam } from 'use-query-params';
import { useQuery, useMutation } from '@apollo/client';
import { PAGINATED_USERS } from 'Contexts/Queries/Remote/User';
import { DELETE_USER } from 'Contexts/Mutations/Remote/User';
import AppLayout from 'Components/AppLayout/AppLayout';
import './UsersStyle.css';

const Users = ({ currentUserId, history }) => {
  const [pageSize, setPageSize] = useQueryParam('pageSize', withDefault(NumberParam, 20));
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [orderBy, setOrderBy] = useQueryParam('orderBy', withDefault(StringParam, 'createdAt_DESC'));
  const [filters, setFilters] = useQueryParam('filters', withDefault(JsonParam, {}));

  const usersQuery = useQuery(PAGINATED_USERS, {
    fetchPolicy: 'network-only',
    variables: { first: pageSize, skip: page * pageSize, orderBy, filters },
  });
  const usersQueryData = usersQuery.data || {};
  const users = usersQueryData?.paginatedUsers?.users || [];
  const usersCount = usersQueryData?.paginatedUsers?.count || 0;

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      notification.success({ message: 'User Deleted' });
      usersQuery.refetch();
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Ooops', description: error.message });
    },
  });

  const doDeleteUser = (userId) => {
    deleteUser({
      variables: { userId },
    });
  };

  const onTableChange = (tablePagination, tableFilters, tableSorter) => {
    setPageSize(tablePagination.pageSize);
    setPage(tablePagination.current ? tablePagination.current - 1 : 0);

    const activeFilter = tableFilters.active ? tableFilters.active[0] === true : undefined;
    const verifiedFilter = tableFilters.verified ? tableFilters.verified[0] === true : undefined;
    const adminFilter = tableFilters.admin ? tableFilters.admin[0] === true : undefined;
    setFilters({
      ...filters,
      active: activeFilter,
      verified: verifiedFilter,
      admin: adminFilter,
    });

    const orderByKey = tableSorter.field || 'createdAt';
    const orderByDirection = tableSorter.order === 'ascend' ? 'ASC' : 'DESC';

    setOrderBy(`${orderByKey}_${orderByDirection}`);
  };

  const columns = [
    {
      title: 'Name/Alias',
      dataIndex: 'name',
      render: (text, record) => (
        <a href={`/users/${record.id}`}>{text || record.alias}</a>
      ),
      fixed: 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Name"
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => {
              setFilters({ ...filters, name: undefined });
              confirm();
            }}
            size="small"
            block
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (active) => <SearchOutlined style={{ color: active ? '#1890ff' : undefined }} />,
      filteredValue: filters.name,
      sorter: true,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Name"
            value={filters.email}
            onChange={(e) => setFilters({ ...filters, email: e.target.value })}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => {
              setFilters({ ...filters, email: undefined });
              confirm();
            }}
            size="small"
            block
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (active) => <SearchOutlined style={{ color: active ? '#1890ff' : undefined }} />,
      filteredValue: filters.email,
      sorter: true,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Verified',
      dataIndex: 'verified',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Verified', value: true },
        { text: 'Not Verified', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Is Admin', value: true },
        { text: 'Not Admin', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 200,
      align: 'center',
      render: (value) => (value && moment(value).format('L LT')) || '',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (value, record) => (
        <Space direction="horizontal">
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => doDeleteUser(record.id)}
          >
            <Button type="danger" disabled={record.id === currentUserId}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const pagination = {
    showSizeChanger: true,
    current: page + 1,
    total: usersCount,
    pageSize,
  };

  return (
    <AppLayout selectedMenu="users">
      <PageHeader
        className="page-title"
        title="Users"
        extra={[
          <Button key="new" type="primary" onClick={() => history.push('/users/new')}>
            New User
          </Button>,
        ]}
      />

      <Layout.Content className="users-content">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={users}
          onChange={onTableChange}
          loading={usersQuery.loading}
          pagination={pagination}
          bordered
          scroll={{ x: true }}
        />
      </Layout.Content>
    </AppLayout>
  );
};

export default Users;
