import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString from 'query-string';
import { withRouter, Route } from 'react-router-dom';
import { initClient } from './Services/ApolloService';
import Root from './Containers/Root/Root';

const App = () => {
  const [apolloClient, setApolloClient] = useState();

  useEffect(() => {
    // Init Apollo Client
    // Apollo Client mus be lazy loaded like this due to AsyncStorage persistor
    initClient().then(setApolloClient);
  }, []);

  if (!apolloClient) return 'Loading...';

  return (
    <QueryParamProvider
      ReactRouterRoute={Route}
      adapter={ReactRouter5Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <Root />
      </ApolloProvider>
    </QueryParamProvider>
  );
};

export default withRouter(App);
