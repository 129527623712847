import { notification } from 'antd';

export const openFileUploader = (cloudinaryOptions, attachmentOptions, onUploaderClose) => {
  const addAttachments = [];
  const cloudinaryOpts = {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOADPRESET,
    resourceType: cloudinaryOptions.resourceType,
    sources: ['local', 'url'],
    context: cloudinaryOptions.context,
    multiple: !cloudinaryOptions ? true : cloudinaryOptions.multiple,
  };

  window.cloudinary.openUploadWidget(cloudinaryOpts, (error, result) => {
    if (error) {
      const toastText = 'There was an error uploading image(s)';
      notification.error({ message: 'Ooops', description: toastText });
    }

    if (result.event === 'success') {
      addAttachments.push({
        type: attachmentOptions.type,
        label: attachmentOptions?.label || result.info.original_filename || result.info.etag,
        contentId: result.info.public_id,
        contentUrl: result.info.secure_url || result.info.url,
        contentMime: `${result.info.resource_type}/${result.info.format}`,
        subject: attachmentOptions.subject,
      });
    }

    if (result.event === 'close') {
      if (!addAttachments.length) return;
      if (attachmentOptions.returnData) {
        onUploaderClose(addAttachments);
      } else {
        onUploaderClose({ addAttachments });
      }
    }
  });
};

export default { openFileUploader };
