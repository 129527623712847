import React from 'react';
import { Form, Input, Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const JsonConfigEditor = ({ configs }) => (
  <Form.List name="configs" initialValue={configs}>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, fieldKey, ...restField }) => (
          <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item
              {...restField}
              name={[name, 'key']}
              fieldKey={[fieldKey, 'key']}
              rules={[{ required: true, message: 'Missing key' }]}
            >
              <Input placeholder="Key" />
            </Form.Item>
            <Form.Item
              {...restField}
              name={[name, 'value']}
              fieldKey={[fieldKey, 'value']}
              rules={[{ required: true, message: 'Missing value' }]}
            >
              <Input placeholder="Value" />
            </Form.Item>
            <MinusCircleOutlined onClick={() => remove(name)} />
          </Space>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add Key-Value Pair
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default JsonConfigEditor;
