import { gql } from '@apollo/client';

export const CREATE_ACHIEVEMENT_RECORD = gql`
  mutation createAchievementRecord($userId: ID!, $data: CreateAchievementRecordInput!) {
    createAchievementRecord(userId: $userId, data: $data) {
      id
      type
      entity
      entityRef
      score
      observation
    }
  }
`;

export default CREATE_ACHIEVEMENT_RECORD;
