import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => {
        if (!cProps || !cProps.authenticated) {
          // const redirectPath = `${props.location.pathname}${props.location.search}`;
          // return <Redirect to={`/login?redirect=${redirectPath}`} />;

          return <Redirect to="/login" />;
        }

        return <C {...props} {...cProps} />;
      }
    }
  />
);
