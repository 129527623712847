import React, { useMemo, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Form, Input, Button, Space, notification, Table, Popconfirm, Card, Row, Col, Divider, Image, Select } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { OBJECT } from 'Contexts/Queries/Remote/Object';
import { OBJECT_TYPES } from 'Contexts/Constants/Object';
import { ATTRIBUTES } from 'Contexts/Queries/Remote/Attribute';
import {
  UPDATE_OBJECT,
  CREATE_OBJECT,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENT,
  SET_DEFAULT_ATTACHMENT,
  ADD_GEOLOCATION,
  UPDATE_GEOLOCATION,
  DELETE_GEOLOCATION,
} from 'Contexts/Mutations/Remote/Object';
import AppLayout from 'Components/AppLayout/AppLayout';
import WrappedMap from 'Components/WrappedMap/WrappedMap';
import { openFileUploader } from 'Services/FileUploader';
import './Style.css';

const EditObject = ({ match, history, location }) => {
  const [form] = Form.useForm();
  const isNew = match.params.objectId === 'new';
  const objectId = isNew ? null : match.params.objectId;
  const selectedMenu = location?.state?.ref || 'objects';
  const [attributes, setAttributes] = useState([]);
  const [newAttribute, setNewAttribute] = useState({ key: '', value: '' });
  const [geolocations, setGeolocations] = useState([]);

  const attributesQuery = useQuery(ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
  });
  const attributesList = attributesQuery.data?.attributes || [];

  const objectQuery = useQuery(OBJECT, {
    fetchPolicy: 'network-only',
    variables: { id: objectId },
    skip: isNew,
    onCompleted: (data) => {
      if (data && data.object) {
        const attrs = data.object.attributes || {};
        const formattedAttrs = Object.keys(attrs).map((key) => ({
          key,
          value: attrs[key],
        }));
        setAttributes(formattedAttrs);
        setGeolocations(data.object.geolocations || []);
      }
    },
  });

  const [updateObject] = useMutation(UPDATE_OBJECT, {
    refetchQueries: [{ query: OBJECT, variables: { id: objectId } }],
    onCompleted: () => {
      notification.success({ message: 'Object Updated!' });
      objectQuery.refetch();
    },
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [createObject] = useMutation(CREATE_OBJECT, {
    onCompleted: ({ createObject: { id } }) => {
      notification.success({ message: 'Object Created!' });
      history.push(`/objects/${id}`);
    },
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [addAttachment] = useMutation(ADD_ATTACHMENT, {
    onCompleted: () => {
      objectQuery.refetch();
    },
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT, {
    onCompleted: () => {
      objectQuery.refetch();
    },
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [setDefaultAttachmentMutation] = useMutation(SET_DEFAULT_ATTACHMENT, {
    onCompleted: () => {
      objectQuery.refetch();
    },
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [addGeolocation] = useMutation(ADD_GEOLOCATION, {
    onCompleted: () => objectQuery.refetch(),
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [updateGeolocation] = useMutation(UPDATE_GEOLOCATION, {
    onCompleted: () => objectQuery.refetch(),
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const [deleteGeolocation] = useMutation(DELETE_GEOLOCATION, {
    onCompleted: () => objectQuery.refetch(),
    onError: (e) => {
      const error = e.graphQLErrors?.[0] || e;
      notification.error({ message: 'Oops', description: error.message });
    },
  });

  const onSubmit = async (values) => {
    const attrs = attributes.reduce((acc, attr) => {
      acc[attr.key] = attr.value.includes(';') ? attr.value.split(';') : attr.value;
      return acc;
    }, {});
    const input = { ...values, attributes: attrs };
    if (isNew) {
      await createObject({ variables: { input } });
    } else {
      await updateObject({ variables: { id: objectId, input } });
    }
  };

  const onUploadClick = () => {
    const cloudinaryOptions = {
      resourceType: 'image',
      multiple: false,
      context: { objectId },
    };
    const attachmentOptions = {
      type: 'IMAGE',
      subject: 'ENTITY_PICTURE',
      returnData: true,
    };
    openFileUploader(cloudinaryOptions, attachmentOptions, (data) => {
      data.forEach((att) => addAttachment({ variables: { objectId, data: att } }));
    });
  };

  const onAttachmentRemove = (attachmentId) => {
    removeAttachment({ variables: { id: attachmentId } });
  };

  const handleSetDefaultAttachment = (attachmentId) => {
    setDefaultAttachmentMutation({ variables: { objectId, attachmentId } });
  };

  const handleAddAttribute = () => {
    if (newAttribute.key && newAttribute.value.length > 0) {
      const existingIndex = attributes.findIndex((attr) => attr.key === newAttribute.key);
      if (existingIndex >= 0) {
        const newAttrs = [...attributes];
        newAttrs[existingIndex] = newAttribute;
        setAttributes(newAttrs);
      } else {
        setAttributes([...attributes, newAttribute]);
      }
      setNewAttribute({ key: '', value: [] });
    }
  };

  const handleRemoveAttribute = (key) => {
    setAttributes(attributes.filter((attr) => attr.key !== key));
  };

  const handleEditAttribute = (record) => {
    setNewAttribute(record);
  };

  const handleMapClick = async (event) => {
    const newGeolocation = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };
    const { data } = await addGeolocation({ variables: { objectId, data: newGeolocation } });
    setGeolocations([...geolocations, data.addObjectGeolocation]);
  };

  const handleMarkerDragEnd = async (event, index) => {
    const current = geolocations[index];
    const updatedGeolocation = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };
    const { data } = await updateGeolocation({ variables: { id: current.id, data: updatedGeolocation } });
    const newGeolocations = [...geolocations];
    newGeolocations[index] = data.updateObjectGeolocation;
    setGeolocations(newGeolocations);
  };

  const handleMarkerRightClick = async (index) => {
    const geolocationToDelete = geolocations[index];
    await deleteGeolocation({ variables: { id: geolocationToDelete.id } });
    setGeolocations(geolocations.filter((_, i) => i !== index));
  };

  const object = useMemo(() => objectQuery?.data?.object || { type: 'PLANT' }, [objectQuery]);
  const attachments = useMemo(() => object?.attachments || [], [object]);
  const defaultAttachment = useMemo(() => object?.defaultAttachment || null, [object]);

  if (objectQuery.loading || attributesQuery.loading) {
    return <div>Loading...</div>;
  }
  if (objectQuery.error || attributesQuery.error) {
    return <div>Error! {objectQuery?.error?.message || attributesQuery?.error?.message}</div>;
  }

  return (
    <AppLayout selectedMenu={selectedMenu}>
      <PageHeader
        className="page-title"
        title={isNew ? 'New Object' : 'Edit Object'}
        subTitle={object.name}
        onBack={() => history.push('/objects')}
      />
      <Layout.Content className="edit-object-content">
        <Form
          form={form}
          layout="horizontal"
          size="large"
          labelAlign="left"
          labelCol={{ md: { span: 4 }, sm: { span: 24 } }}
          wrapperCol={{ md: { span: 16 }, sm: { span: 24 } }}
          onFinish={onSubmit}
          initialValues={object}
        >
          <Row>
            <Col xs={24}>
              <br /><strong>Object Information</strong><br /><br />
            </Col>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input the name!' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please input the type!' }]}
              >
                <Select placeholder="Type">
                  {(OBJECT_TYPES).map((name) => (
                    <Select.Option key={name} value={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="description" label="Description">
                <Input.TextArea rows={4} placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <strong>Object Properties</strong><br /><br />
            </Col>
            <Col xs={24}>
              <Form.Item label="Attributes">
                <Space>
                  <Select
                    placeholder="Select Key"
                    value={newAttribute.key}
                    onChange={(key) => setNewAttribute({ ...newAttribute, key, value: [] })}
                    style={{ width: 200 }}
                  >
                    {attributesList.map((attr) => (
                      <Select.Option key={attr.name} value={attr.name}>
                        {attr.label || attr.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {newAttribute.key && attributesList.find((attr) => attr.name === newAttribute.key).configs.enum === false ? (
                    <Input
                      placeholder="Enter Value"
                      value={newAttribute.value[0] || ''}
                      onChange={(e) => setNewAttribute({ ...newAttribute, value: [e.target.value] })}
                      style={{ width: 400 }}
                    />
                  ) : (
                    <Select
                      mode="multiple"
                      placeholder="Select Values"
                      value={newAttribute.value}
                      onChange={(value) => setNewAttribute({ ...newAttribute, value })}
                      style={{ width: 400 }}
                      disabled={!newAttribute.key}
                    >
                      {newAttribute.key && attributesList.find((attr) => attr.name === newAttribute.key).values.map((value) => (
                        <Select.Option key={value.value} value={value.value}>
                          {value.label || value.value}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  <Button type="dashed" onClick={handleAddAttribute}>
                    <PlusOutlined /> Add Attribute
                  </Button>
                </Space>
                <Table
                  dataSource={attributes}
                  columns={[
                    {
                      title: 'Key',
                      dataIndex: 'key',
                      key: 'key',
                      onCell: (record) => ({
                        onClick: () => handleEditAttribute(record),
                      }),
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      key: 'value',
                      onCell: (record) => ({
                        onClick: () => handleEditAttribute(record),
                      }),
                    },
                    {
                      title: 'Actions',
                      key: 'actions',
                      render: (text, record) => (
                        <Popconfirm title="Are you sure?" onConfirm={() => handleRemoveAttribute(record.key)}>
                          <Button type="danger">Delete</Button>
                        </Popconfirm>
                      ),
                    },
                  ]}
                  rowKey="key"
                  pagination={false}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isNew && (
            <Row>
              <Col xs={24}>
                <strong>Geolocations</strong>
              </Col>
              <Col xs={24}>
                Click on the map to add a new geolocation.<br />
                Drag the markers to update the geolocation.<br />
                Right-click on the markers to delete the geolocation.<br /><br />
              </Col>
              <Col xs={24}>
                <WrappedMap
                  geolocations={geolocations}
                  onMapClick={handleMapClick}
                  onMarkerDragEnd={handleMarkerDragEnd}
                  onMarkerRightClick={handleMarkerRightClick}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '500px' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                /><br /><br />
              </Col>
            </Row>
          )}
          {!isNew && (
            <Row>
              <Col xs={24}>
                <strong>Object Files</strong><br /><br />
              </Col>
              <Col xs={24}>
                <Form.Item>
                  <Space>
                    {attachments.map((att) => (
                      <Card
                        key={att.id}
                        cover={<Image width={200} src={att.contentUrl} />}
                      >
                        <Space>
                          <Button
                            onClick={() => handleSetDefaultAttachment(att.id)}
                            disabled={defaultAttachment?.id === att.id}
                            block
                          >
                            {defaultAttachment?.id === att.id ? 'Default' : 'Set as Default'}
                          </Button>
                          <Button onClick={() => onAttachmentRemove(att.id)} block>
                            Remove
                          </Button>
                        </Space>
                      </Card>
                    ))}
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Button onClick={onUploadClick} icon={<UploadOutlined />}>
                    Upload Attachment
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider />
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit" loading={objectQuery.loading} block>
              {isNew ? 'Create' : 'Update'}
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </AppLayout>
  );
};

export default EditObject;
