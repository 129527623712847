import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { useQuery } from '@apollo/client';
import { CURRENT_USER } from 'Contexts/Queries/Local/CurrentUser';
import Header from './Header';
import SideMenu from './SideMenu';
import './AppLayoutStyle.css';

const AppLayout = ({ children, selectedMenu = 'dashboard' }) => {
  const currentUserQuery = useQuery(CURRENT_USER);
  const currentUser = currentUserQuery.data?.currentUser || {};

  return (
    <Layout className="app-layout">
      <Header currentUser={currentUser} />
      <Layout>
        <SideMenu selectedMenu={selectedMenu} currentUser={currentUser} />
        <Layout className="app-layout-main">{children}</Layout>
      </Layout>
    </Layout>
  );
};

AppLayout.propTypes = {
  selectedMenu: PropTypes.string,
};

export default AppLayout;
