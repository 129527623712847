import React, { useMemo, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Row, Col, Divider, Form, Input, Switch, Button, Upload, Tabs, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { USER } from 'Contexts/Queries/Remote/User';
import { UPDATE_USER } from 'Contexts/Mutations/Remote/User';
import { openFileUploader } from 'Services/FileUploader';
import AppLayout from 'Components/AppLayout/AppLayout';
import Achievements from 'Components/Achievements/Achievements';
import Badges from 'Components/Badges/Badges';
import AchievementForm from './AchievementForm';
import './EditUserStyle.css';

const EditUser = ({ currentUserId, match, history, location }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const userId = match.params.userId;
  const selectedMenu = location?.state?.ref || 'users';
  const isItMe = currentUserId === userId;

  const userQuery = useQuery(USER, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const user = useMemo(() => (userQuery.data && userQuery.data.user) || {}, [userQuery]);

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: USER, variables: { userId } }],
    onCompleted: () => {
      notification.success({ message: 'User Updated!' });
      setLoading(false);
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Oops', description: error.message });
      setLoading(false);
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    await updateUser({ variables: { userId, data } });
  };

  const onAttachmentRemove = (attachment) => {
    const data = { removeAttachment: attachment.uid };
    updateUser({ variables: { userId, data } });
  };

  const onUploaderClose = (data) => {
    updateUser({ variables: { userId, data } });
  };

  const onAvatarUploadClick = () => {
    const cloudinaryOptions = {
      resourceType: 'image',
      multiple: false,
      context: { userId: currentUserId },
    };
    const attachmentOptions = {
      type: 'IMAGE',
      subject: 'PROFILE_PICTURE',
    };
    openFileUploader(cloudinaryOptions, attachmentOptions, onUploaderClose);
  };

  const avatarFile = useMemo(() => {
    const avatar = user.avatar;
    return avatar && {
      uid: avatar.id,
      name: avatar.label,
      url: avatar.contentUrl,
      status: 'done',
    };
  }, [user]);

  if (userQuery.loading) {
    return <div>Loading...</div>;
  }

  if (userQuery.error) {
    return <div>Error! {userQuery.error.message}</div>;
  }

  const tabItems = [
    {
      key: 'edit',
      label: 'Edit User',
      children: (
        <Form
          form={form}
          layout="horizontal"
          size="large"
          labelAlign="left"
          labelCol={{ md: { span: 4 }, sm: { span: 24 } }}
          wrapperCol={{ md: { span: 16 }, sm: { span: 24 } }}
          onFinish={onSubmit}
          initialValues={{
            name: user.name,
            alias: user.alias,
            email: user.email,
            phone: user.phone,
            active: user.active,
            verified: user.verified,
            admin: user.admin,
          }}
        >
          <Row>
            <Col xs={24}>
              <strong>User Information</strong><br /><br />
            </Col>
            <Col xs={24}>
              <Form.Item label="Avatar:">
                {avatarFile ? (
                  <Upload
                    listType="picture-card"
                    fileList={[avatarFile]}
                    onRemove={onAttachmentRemove}
                  />
                ) : (
                  <Button onClick={onAvatarUploadClick} block>
                    <UploadOutlined /> Upload Avatar
                  </Button>
                )}
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Provide a name for the user' },
                  { whitespace: true, message: 'Provide a proper name' },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="alias"
                label="Alias"
                rules={[
                  { whitespace: true, message: 'Provide a proper alias' },
                ]}
              >
                <Input placeholder="Alias" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone #"
                rules={[
                  { whitespace: true, message: 'Provide a proper phone number' },
                ]}
              >
                <Input placeholder="Phone #" />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { required: true, message: 'Provide an email for user' },
                  { type: 'email', message: 'Inform a valid email' },
                ]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { whitespace: true, message: 'Provide a proper password' },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <strong>Access Details</strong><br /><br />
              <Form.Item label="Is Admin?" name="admin" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Active" name="active" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Verified" name="verified" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit" disabled={loading} block>
              Save
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: 'achievements',
      label: 'Achievements',
      children: (
        <>
          <AchievementForm userId={userId} />
          <Divider />
          <Achievements userId={userId} />
        </>
      ),
    },
    {
      key: 'badges',
      label: 'Badges',
      children: <Badges userId={userId} />,
    },
  ];

  return (
    <AppLayout selectedMenu={selectedMenu}>
      <PageHeader
        className="page-title"
        title={isItMe ? 'My Profile' : 'Edit User'}
        subTitle={user.name}
        onBack={selectedMenu !== 'updateMe' && (() => history.goBack())}
      />
      <Layout.Content className="edit-user-content">
        {user.id && <Tabs defaultActiveKey="edit" size="large" centered items={tabItems} />}
      </Layout.Content>
    </AppLayout>
  );
};

export default EditUser;
