import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const querystring = (rawName, url = window.location.href) => {
  const name = rawName.replace(/[[]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  if (rawName === 'redirect') {
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return decodeURIComponent(results[0].replace(/\+/g, ' '));
};

export default ({ component: C, props: cProps, ...rest }) => {
  const redirectBase = querystring('redirect');
  const origin = querystring('origin');
  const redirect = [redirectBase, origin].join('');

  const to = (!redirect || redirect === '') ? '/' : redirect;

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (!cProps || !cProps.authenticated) {
            return <C {...props} {...cProps} />;
          }

          return <Redirect to={to} />;
        }
      }
    />
  );
};
