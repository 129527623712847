import React from 'react';
import { Layout, Menu, Typography } from 'antd';
import { signOut } from 'Services/AuthService';
import './AppLayoutStyle.css';

const { Header } = Layout;

const menuItems = [
  {
    key: 'logout',
    label: 'Logout',
    onClick: signOut,
    className: 'app-layout-header-menu-logout',
  },
];

const AppHeader = () => (
  <Header className="app-layout-header">
    <Typography.Title level={3} className="app-layout-header-logo">
      Passarim
    </Typography.Title>
    <Menu className="app-layout-header-menu" theme="dark" mode="horizontal" items={menuItems} />
  </Header>
);

export default AppHeader;
