import { gql } from '@apollo/client';

export const BADGES = gql`
  query badges($userId: ID) {
    badges {
      id
      identifier
      title
      description
      extraText
      imageUrl
      score
      dateEarned(userId: $userId)
    }
  }
`;

export default BADGES;
