import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data ) {
      id
      name
      alias
      email
      phone
      active
      verified
      admin
      createdAt
      updatedAt
      avatar {
        id
        label
        contentId
        contentUrl
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $data: UpdateUserInput!) {
    updateUser(userId: $userId, data: $data ) {
      id
      name
      alias
      email
      phone
      active
      verified
      admin
      createdAt
      updatedAt
      avatar {
        id
        label
        contentId
        contentUrl
      }
    }
  }
`;
