import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { getToken, signOut } from './AuthService'; // eslint-disable-line import/no-cycle

let apolloClient = null;

export const getClient = () => apolloClient;

export const isTokenError = (e) => {
  if (!e) return false;

  const hasErrorInGraphQL = (() => {
    const graphqlErrors = e.graphQLErrors;
    if (!graphqlErrors || !graphqlErrors.length) return false;

    const tokenErrors = ['UNAUTHENTICATED', 'LOGIN_EXPIRED'];
    const hasTokenError = graphqlErrors.find((error) => tokenErrors.includes(error.type));

    return hasTokenError;
  })();

  const hasErrorInMessage = (() => {
    const errorMessage = e.message;
    if (!errorMessage || !errorMessage.length) return false;

    const isUnauthenticated = errorMessage.includes('UNAUTHENTICATED');
    const isExpired = errorMessage.includes('LOGIN_EXPIRED');

    return isUnauthenticated || isExpired;
  })();

  return hasErrorInGraphQL || hasErrorInMessage;
};

export const getErrorMessages = (e) => {
  const graphqlErrors = e && e.graphQLErrors;

  if (!graphqlErrors || !graphqlErrors.length) {
    return e?.message || null;
  }

  return graphqlErrors.reduce((acc, error) => {
    if (!error.message) return acc;
    if (!acc) return error.message;
    return `${acc}\n${error.message}`;
  }, null);
};

export const onTokenError = async () => {
  await signOut();
  alert('Your session has expired. Please log in again.');
};

export const initClient = async () => {
  if (apolloClient) return apolloClient;

  const cache = new InMemoryCache();

  await persistCache({ cache, storage: new LocalStorageWrapper(window.localStorage) });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    if (!token) return { headers: { ...headers } };
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    onError: (e) => {
      if (isTokenError(e)) onTokenError();
    },
  });

  return apolloClient;
};

export const handleError = (e, customMessage, callback) => {
  if (isTokenError(e)) return;

  const errorMessage = customMessage || getErrorMessages(e) || '';
  alert(errorMessage);

  if (callback) return callback(e); // eslint-disable-line consistent-return
};

export default {
  getClient,
  isTokenError,
  getErrorMessages,
  onTokenError,
  initClient,
  handleError,
};
