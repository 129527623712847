import { gql } from '@apollo/client';

export const ACHIEVEMENTS = gql`
  query achievements($userId: ID!) {
    achievements(userId: $userId) {
      id
      type
      entity
      entityRef
      score
      observation
    }
  }
`;

export default ACHIEVEMENTS;
