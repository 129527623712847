import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authenticated from './Authenticated';
import Unauthenticated from './Unauthenticated';
import NotFound from './NotFound';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import Users from '../Users/Users';
import VerifyUsers from '../Users/VerifyUsers/VerifyUsers';
import NewUser from '../Users/NewUser/NewUser';
import EditUser from '../Users/EditUser/EditUser';
import Objects from '../Objects/Objects';
import EditObject from '../Objects/EditObject';
import Attributes from '../Attributes/Attributes';

export default ({ childProps }) => (
  <Switch>
    <Unauthenticated
      exact
      path="/login"
      component={Login}
      props={childProps}
    />
    <Authenticated
      exact
      path="/"
      component={Dashboard}
      props={childProps}
    />
    <Authenticated
      exact
      path="/users"
      component={Users}
      props={childProps}
    />
    <Authenticated
      exact
      path="/users/new"
      component={NewUser}
      props={childProps}
    />
    <Authenticated
      exact
      path="/users/verify"
      component={VerifyUsers}
      props={childProps}
    />
    <Authenticated
      exact
      path="/users/:userId"
      component={EditUser}
      props={childProps}
    />
    <Authenticated
      exact
      path="/users/:userId/me"
      key="updateMe"
      component={EditUser}
      props={childProps}
    />

    <Authenticated
      exact
      path="/objects"
      component={Objects}
      props={childProps}
    />
    <Authenticated
      exact
      path="/objects/:objectId"
      component={EditObject}
      props={childProps}
    />
    <Authenticated
      exact
      path="/attributes"
      component={Attributes}
      props={childProps}
    />
    <Route component={NotFound} />
  </Switch>
);
