import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Table, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { PAGINATED_USERS } from 'Contexts/Queries/Remote/User';
import AppLayout from 'Components/AppLayout/AppLayout';
import '../UsersStyle.css';

const VerifyUsers = () => {
  const [pageSize, setPageSize] = useState(5000);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt_DESC');
  const [filters, setFilters] = useState({});

  const usersQuery = useQuery(PAGINATED_USERS, {
    fetchPolicy: 'network-only',
    variables: { first: pageSize, skip: page * pageSize, orderBy, filters },
  });
  const usersQueryData = usersQuery.data || {};
  const users = usersQueryData?.paginatedUsers?.users || [];
  const usersCount = usersQueryData?.paginatedUsers?.count || 0;

  const unverifiedUsers = users.filter((u) => u.verified === false);

  const onTableChange = (tablePagination, tableFilters, tableSorter) => {
    setPageSize(tablePagination.pageSize);
    setPage(tablePagination.current - 1);

    const activeFilter = tableFilters.active ? tableFilters.active[0] === true : undefined;
    const verifiedFilter = tableFilters.verified ? tableFilters.verified[0] === true : undefined;
    const roleTypesFilter = tableFilters['role.role'] ? tableFilters['role.role'] : undefined;
    setFilters({
      ...filters,
      active: activeFilter,
      verified: verifiedFilter,
      roleTypes: roleTypesFilter,
    });

    const orderByKey = tableSorter.field || 'createdAt';
    const orderByDirection = tableSorter.order === 'ascend' ? 'ASC' : 'DESC';

    setOrderBy(`${orderByKey}_${orderByDirection}`);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => (
        <a href={`/users/${record.id}`}>{text}</a>
      ),
      fixed: 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Name"
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => {
              setFilters({ ...filters, name: undefined });
              confirm();
            }}
            size="small"
            block
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (active) => <SearchOutlined style={{ color: active ? '#1890ff' : undefined }} />,
      filteredValue: filters.name,
      sorter: true,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Name"
            value={filters.email}
            onChange={(e) => setFilters({ ...filters, email: e.target.value })}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => {
              setFilters({ ...filters, email: undefined });
              confirm();
            }}
            size="small"
            block
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (active) => <SearchOutlined style={{ color: active ? '#1890ff' : undefined }} />,
      filteredValue: filters.email,
      sorter: true,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Verified',
      dataIndex: 'verified',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Verified', value: true },
        { text: 'Not Verified', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: 100,
      align: 'center',
      filterMultiple: false,
      filters: [
        { text: 'Is Admin', value: true },
        { text: 'Not Admin', value: false },
      ],
      render: (value) => (value ? '✅' : '🔴'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 200,
      align: 'center',
      render: (value) => (value && moment(value).format('L LT')) || '',
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];

  const pagination = {
    showSizeChanger: true,
    current: page + 1,
    total: usersCount,
    pageSize,
  };

  return (
    <AppLayout selectedMenu="verifyUsers">
      <PageHeader
        className="page-title"
        title="User Verification"
      />

      <Layout.Content className="users-content">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={unverifiedUsers}
          onChange={onTableChange}
          loading={usersQuery.loading}
          pagination={pagination}
          bordered
          scroll={{ x: true }}
        />
      </Layout.Content>
    </AppLayout>
  );
};

export default VerifyUsers;
