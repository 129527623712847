import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout } from 'antd';
import AppLayout from 'Components/AppLayout/AppLayout';
import './DashboardStyle.css';

const Dashboard = () => (
  <AppLayout selectedMenu="dashboard">
    <PageHeader title="Dashboard" />
    <Layout.Content className="dashboard-content">
      Dashboard
    </Layout.Content>
  </AppLayout>
);

export default Dashboard;
