import React, { useState } from 'react';
import { Row, Col, Divider, Form, Input, InputNumber, Select, Button, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_ACHIEVEMENT_RECORD } from 'Contexts/Mutations/Remote/Achievements';
import { ACHIEVEMENTS } from 'Contexts/Queries/Remote/Achievements';

const AchievementForm = ({ userId }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [createAchievementRecord] = useMutation(CREATE_ACHIEVEMENT_RECORD, {
    variables: { userId },
    refetchQueries: [{ query: ACHIEVEMENTS, variables: { userId } }],
    onCompleted: () => {
      notification.success({ message: 'Achievement created!' });
      form.resetFields();
      setLoading(false);
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Ooops', description: error.message });

      setLoading(false);
    },
  });

  const onSubmit = async (data) => {
    await setLoading(true);
    await createAchievementRecord({ variables: { data } });
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      size="large"
      labelAlign="left"
      labelCol={{ md: { span: 4 }, sm: { span: 24 } }}
      wrapperCol={{ md: { span: 16 }, sm: { span: 24 } }}
      onFinish={onSubmit}
      initialValues={{ type: 'MANUAL', score: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Select a type' }]}
          >
            <Select>
              <Select.Option value="MANUAL">Manual</Select.Option>
              <Select.Option value="VERIFIED">User Verified</Select.Option>
              <Select.Option value="INSTAGRAM_FOLLOW">Followed on Instagram</Select.Option>
              <Select.Option value="FACEBOOK_LIKE">Like on Facebook</Select.Option>
              <Select.Option value="SOCIAL_SHARE">Share on Social Media</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="score" label="Score">
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="observation"
            label="Observation"
            rules={[{ whitespace: true, message: 'Provide a proper observation' }]}
          >
            <Input placeholder="Observation" />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit" disabled={loading} block>
          Create Achievement Record for User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AchievementForm;
