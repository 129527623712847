import { gql } from '@apollo/client';

export const USER = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      id
      name
      alias
      email
      phone
      active
      verified
      admin
      createdAt
      updatedAt
      avatar {
        id
        label
        contentId
        contentUrl
      }
    }
  }
`;

export const PAGINATED_USERS = gql`
  query paginatedUsers(
    $first: Int
    $skip: Int
    $orderBy: PaginatedUsersOrderBy
    $filters: PaginatedUsersFiltersInput
  ){
    paginatedUsers(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filters: $filters
    ){
      count
      users {
        id
        name
        alias
        email
        phone
        active
        verified
        admin
        createdAt
        updatedAt
        avatar {
          id
          label
          contentId
          contentUrl
        }
      }
    }
  }
`;
