import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CURRENT_USER } from 'Contexts/Queries/Local/CurrentUser';
import Router from '../Router/Router';

const Root = () => {
  const currentUserQuery = useQuery(CURRENT_USER);

  const loading = (currentUserQuery.loading);
  const error = (currentUserQuery.error);

  const currentUser = useMemo(
    () => currentUserQuery.data?.currentUser || {},
    [currentUserQuery.data],
  );

  const currentUserId = currentUser && currentUser?.id;

  const currentUserRole = currentUser && currentUser?.role?.role;

  const authenticated = !!currentUserId;

  if (error) return 'Error';
  if (loading) return 'Loading...';

  const childProps = {
    authenticated,
    currentUser,
    currentUserId,
    currentUserRole,
  };

  return <Router childProps={childProps} />;
};

export default Root;
