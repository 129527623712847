import { makeVar, gql } from '@apollo/client';
import { getClient } from './ApolloService'; // eslint-disable-line

export const authenticatedVar = makeVar(false);
export const currentUserVar = makeVar(null);

export const signIn = async (token, user) => {
  window.localStorage.setItem('@token', token);
  window.localStorage.setItem('@userId', user.id);

  authenticatedVar(true);
  currentUserVar(user);

  const apolloClient = getClient();
  await apolloClient.cache.writeQuery({
    query: gql`
      query GetAuthData {
        authenticated @client
        currentUser @client
      }
    `,
    data: {
      authenticated: true,
      currentUser: user,
    },
  });
};

export const signOut = async () => {
  window.localStorage.removeItem('@token');
  window.localStorage.removeItem('@userId');

  authenticatedVar(false);
  currentUserVar(null);

  const apolloClient = getClient();
  await apolloClient.resetStore();
  await apolloClient.cache.writeQuery({
    query: gql`
      query GetAuthData {
        authenticated @client
        currentUser @client
      }
    `,
    data: {
      authenticated: false,
      currentUser: null,
    },
  });
};

export const getToken = () => (
  window.localStorage.getItem('@token')
);

export const getUserId = () => (
  window.localStorage.getItem('@userId')
);

export const isActive = (currentUser) => {
  if (!currentUser?.active) return false;
  return currentUser.active;
};

export default {
  signIn,
  signOut,
  getToken,
  isActive,
};
