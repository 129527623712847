import { gql } from '@apollo/client';

export const AUTH = gql`
  mutation auth($email: String!, $password: String!) {
    auth(
      data: { 
        provider: EMAIL,
        providerId: $email,
        providerSecret: $password
      }
    ) {
      token
      user {
        id
        name
        alias
        email
        phone
        active
        verified
        admin
        createdAt
        updatedAt
        avatar {
          id
          label
          contentId
          contentUrl
        }
      }
    }
  }
`;

export default AUTH;
