import React from 'react';
import moment from 'moment';
import { Row, Col, Card } from 'antd';
import { useQuery } from '@apollo/client';
import { BADGES } from 'Contexts/Queries/Remote/Badges';

const Badges = ({ userId }) => {
  const badgesQuery = useQuery(BADGES, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const badges = badgesQuery?.data?.badges || [];

  return (
    <Row gutter={16}>
      {badges.map((badge) => (
        <Col key={badge.id} span={8}>
          <Card title={badge.title} extra={badge.extraText}>
            <p>{badge.description}</p>
            {badge.dateEarned && (
              <p>{`Earned: ${moment(badge.dateEarned).format('L LT')}`}</p>
            )}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Badges;
