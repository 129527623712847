import { gql } from '@apollo/client';

export const CREATE_ATTRIBUTE = gql`
  mutation CreateAttribute($data: AttributeInput!) {
    createAttribute(data: $data) {
      id
      name
      label
      description
      icon
      values
      configs
    }
  }
`;

export const UPDATE_ATTRIBUTE = gql`
  mutation UpdateAttribute($id: ID!, $data: AttributeInput!) {
    updateAttribute(id: $id, data: $data) {
      id
      name
      label
      description
      icon
      values
      configs
    }
  }
`;

export const DELETE_ATTRIBUTE = gql`
  mutation DeleteAttribute($id: ID!) {
    deleteAttribute(id: $id)
  }
`;

export const ADD_ATTRIBUTE_VALUE = gql`
  mutation AddAttributeValue($attributeId: ID!, $valueData: AttributeValueInput!) {
    addAttributeValue(attributeId: $attributeId, valueData: $valueData) {
      id
      values
    }
  }
`;

export const UPDATE_ATTRIBUTE_VALUE = gql`
  mutation UpdateAttributeValue($attributeId: ID!, $valueData: AttributeValueInput!) {
    updateAttributeValue(attributeId: $attributeId, valueData: $valueData) {
      id
      values
    }
  }
`;

export const DELETE_ATTRIBUTE_VALUE = gql`
  mutation DeleteAttributeValue($attributeId: ID!, $value: String!) {
    deleteAttributeValue(attributeId: $attributeId, value: $value) {
      id
      values
    }
  }
`;
