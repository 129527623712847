import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query currentUser {
    currentUser @client {
      id
      name
      alias
      admin
      createdAt
      updatedAt
    }
  }
`;

export default CURRENT_USER;
