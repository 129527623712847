import React, { useState } from 'react';
import moment from 'moment';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Table, Popconfirm, Button, Input, notification, Space, Image, Modal, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQueryParam, withDefault, StringParam, NumberParam, ArrayParam, ObjectParam } from 'use-query-params';
import { useQuery, useMutation } from '@apollo/client';
import { PAGINATED_OBJECTS } from 'Contexts/Queries/Remote/Object';
import { ATTRIBUTES } from 'Contexts/Queries/Remote/Attribute';
import { DELETE_OBJECT } from 'Contexts/Mutations/Remote/Object';
import { OBJECT_TYPES } from 'Contexts/Constants/Object';
import AppLayout from 'Components/AppLayout/AppLayout';

const Objects = ({ history }) => {
  const [pageSize, setPageSize] = useQueryParam('pageSize', withDefault(NumberParam, 20));
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [orderBy, setOrderBy] = useQueryParam('orderBy', withDefault(StringParam, 'name_ASC'));
  const [defaultAttachment, setDefaultAttachment] = useQueryParam('defaultAttachment', withDefault(StringParam, undefined));
  const [query, setQuery] = useQueryParam('query', withDefault(StringParam, undefined));
  const [type, setType] = useQueryParam('type', withDefault(StringParam, undefined));
  const [selectedAttributes, setSelectedAttributes] = useQueryParam('selectedAttributes', withDefault(ArrayParam, []));
  const [attributesFilter, setAttributesFilter] = useQueryParam('attributes', withDefault(ObjectParam, {}));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const objectsQuery = useQuery(PAGINATED_OBJECTS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        query,
        type,
        pageSize,
        page,
        sortBy: orderBy.split('_')[0],
        sortOrder: orderBy.split('_')[1],
        defaultAttachment: !defaultAttachment ? undefined : (defaultAttachment === 'yes'),
        filters: Object.keys(attributesFilter).map((key) => ({
          key,
          values: attributesFilter[key]?.split(','),
        })),
      },
    },
  });

  const attributesQuery = useQuery(ATTRIBUTES, { fetchPolicy: 'cache-and-network' });
  const attributesList = attributesQuery.data?.attributes || [];

  const objectsQueryData = objectsQuery.data || {};
  const objects = objectsQueryData?.paginatedObjects?.objects || [];
  const objectsCount = objectsQueryData?.paginatedObjects?.count || 0;

  const [deleteObject] = useMutation(DELETE_OBJECT, {
    onCompleted: () => {
      notification.success({ message: 'Object Deleted' });
      objectsQuery.refetch();
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Ooops', description: error.message });
    },
  });

  const doDeleteObject = (objectId) => {
    deleteObject({
      variables: { id: objectId },
    });
  };

  const onTableChange = async (pagination, tableFilters, sorter) => {
    const defaultAttachmentFilter = tableFilters.defaultAttachment
      ? tableFilters.defaultAttachment
      : undefined;

    await setDefaultAttachment(defaultAttachmentFilter);

    const typeFilter = tableFilters.type ? tableFilters.type[0] : undefined;
    await setType(typeFilter);

    const orderByKey = sorter.field || 'createdAt';
    const orderByDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';

    await setOrderBy(`${orderByKey}_${orderByDirection}`);

    const newAttributesFilter = {};
    selectedAttributes.forEach((key) => {
      if (tableFilters[key]) {
        newAttributesFilter[key] = tableFilters[key];
      }
    });

    setAttributesFilter(newAttributesFilter);

    await setPageSize(pagination.pageSize);
    await setPage(pagination.current ? pagination.current : 0);
  };

  const handleSelectAttributes = (selectedKeys) => {
    setSelectedAttributes(selectedKeys);
  };

  const dynamicColumns = selectedAttributes.map((key) => {
    const attr = attributesList.find((a) => a.name === key);
    return ({
      title: attr.label || attr.name,
      dataIndex: ['attributes', attr.name],
      align: 'center',
      key: attr.name,
      filters: attr.values.map((value) => ({ text: value.label || value, value: value.value || value })),
      filteredValue: attributesFilter[attr.name]?.split(',') || null,
      onFilter: (value, record) => (record.attributes[attr.name] || []).includes(value),
    });
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => (
        <a href={`/objects/${record.id}`}>{text}</a>
      ),
      fixed: 'left',
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Name"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => {
              setQuery(undefined);
              confirm();
            }}
            size="small"
            block
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (active) => <SearchOutlined style={{ color: active ? '#1890ff' : undefined }} />,
      filteredValue: query ? [query] : null,
      sorter: true,
      defaultSortOrder: orderBy.split('_')[0] === 'name' ? (orderBy.split('_')[1] === 'ASC' ? 'ascend' : 'descend') : undefined, // eslint-disable-line
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      width: 200,
      filteredValue: type ? [type] : null,
      filterMultiple: false,
      filters: OBJECT_TYPES.map((t) => ({ text: t, value: t })),
    },
    {
      title: 'Default Attachment',
      dataIndex: 'defaultAttachment',
      render: (value) => {
        if (value?.contentUrl) {
          return <Image alt={value.label} src={value.contentUrl} height={100} />;
        }

        return (value ? '✅' : '🔴');
      },
      align: 'center',
      width: 200,
      filteredValue: defaultAttachment ? [defaultAttachment] : null,
      filterMultiple: false,
      filters: [
        { text: 'Has Default Attachment', value: 'yes' },
        { text: 'No Default Attachment', value: 'no' },
      ],
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 200,
      align: 'center',
      render: (value) => (value && moment(value).format('L LT')) || '',
      sorter: true,
      defaultSortOrder: orderBy.split('_')[0] === 'createdAt' ? (orderBy.split('_')[1] === 'ASC' ? 'ascend' : 'descend') : undefined, // eslint-disable-line
    },
    ...dynamicColumns,
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 200,
      render: (value, record) => (
        <Space direction="horizontal">
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => doDeleteObject(record.id)}
          >
            <Button type="danger">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const pagination = {
    showSizeChanger: true,
    current: page,
    total: objectsCount,
    pageSize,
  };

  return (
    <AppLayout selectedMenu="objects">
      <PageHeader
        className="page-title"
        title="Objects"
        extra={[
          <Button key="columns" type="default" onClick={() => setIsModalVisible(true)}>
            Manage Columns
          </Button>,
          <Button key="new" type="primary" onClick={() => history.push('/objects/new')}>
            New Object
          </Button>,
        ]}
      />

      <Layout.Content className="objects-content">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={objects}
          onChange={onTableChange}
          loading={objectsQuery.loading}
          pagination={pagination}
          bordered
          scroll={{ x: true }}
        />
      </Layout.Content>

      <Modal
        title="Manage Columns"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <Checkbox.Group
          options={attributesList.map((attr) => ({ label: attr.label || attr.name, value: attr.name }))}
          value={selectedAttributes}
          onChange={handleSelectAttributes}
        />
      </Modal>
    </AppLayout>
  );
};

export default Objects;
