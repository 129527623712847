import React from 'react';
import moment from 'moment';
import { Row, Col, Table } from 'antd';
import { useQuery } from '@apollo/client';
import { ACHIEVEMENTS } from 'Contexts/Queries/Remote/Achievements';

const Achievements = ({ userId }) => {
  const achievementsQuery = useQuery(ACHIEVEMENTS, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const achievements = achievementsQuery?.data?.achievements || [];

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: (val) => <b>{moment(val).format('L LT')}</b>,
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: 'Observation',
      dataIndex: 'observation',
      align: 'center',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      align: 'right',
      width: 200,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={achievements}
          loading={achievementsQuery.loading}
          pagination={false}
          bordered
        />
      </Col>
    </Row>
  );
};

export default Achievements;
