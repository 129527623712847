import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Table, Popconfirm, Button, Input, Modal, Form, notification, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { ATTRIBUTES } from 'Contexts/Queries/Remote/Attribute';
import { CREATE_ATTRIBUTE, UPDATE_ATTRIBUTE, DELETE_ATTRIBUTE } from 'Contexts/Mutations/Remote/Attributes';
import AppLayout from 'Components/AppLayout/AppLayout';
import JsonConfigEditor from './JsonConfigEditor';

const Attributes = () => {
  const { data, refetch } = useQuery(ATTRIBUTES);
  const [createAttribute] = useMutation(CREATE_ATTRIBUTE);
  const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE);
  const [deleteAttribute] = useMutation(DELETE_ATTRIBUTE);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAttribute, setCurrentAttribute] = useState(null);
  const [form] = Form.useForm();

  const defaultConfigs = {
    trait: false,
    filter: false,
    visible: false,
    multiple: false,
    required: false,
    filterStyle: 'CHECKBOX',
  };

  const showModal = (isNew = false) => {
    if (isNew) {
      form.setFieldValue('configs', Object.entries(defaultConfigs).map(([key, value]) => ({ key, value })));
    }

    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentAttribute(null);
    form.resetFields();
  };

  const handleEdit = (attribute) => {
    setIsEditing(true);
    setCurrentAttribute(attribute);
    form.setFieldsValue({
      ...attribute,
      configs: Object.entries(attribute.configs || {}).map(([key, value]) => ({ key, value })),
    });
    showModal();
  };

  const handleDelete = async (id) => {
    await deleteAttribute({ variables: { id } });
    refetch();
    notification.success({ message: 'Attribute Deleted' });
  };

  const handleFormSubmit = async (values) => {
    const transformedConfigs = (values.configs || []).reduce((acc, { key, value }) => {
      let transformedValue = value;
      if (typeof value === 'string') {
        const lowerValue = value.toLowerCase();
        if (lowerValue === 'true') {
          transformedValue = true;
        } else if (lowerValue === 'false') {
          transformedValue = false;
        }
      }
      return { ...acc, [key]: transformedValue };
    }, {});

    const formattedValues = {
      ...values,
      configs: { ...defaultConfigs, ...transformedConfigs },
    };

    if (isEditing) {
      await updateAttribute({ variables: { id: currentAttribute.id, data: formattedValues } });
      notification.success({ message: 'Attribute Updated' });
    } else {
      await createAttribute({ variables: { data: formattedValues } });
      notification.success({ message: 'Attribute Created' });
    }
    refetch();
    handleCancel();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Label',
      dataIndex: 'label',
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.id)}>
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout selectedMenu="attributes">
      <PageHeader
        className="page-title"
        title="Attributes"
        extra={[
          <Button key="add" type="primary" onClick={() => showModal(true)}>
            Add Attribute
          </Button>,
        ]}
      />

      <Layout.Content className="objects-content">
        <Table dataSource={data?.attributes} columns={columns} rowKey="id" />
      </Layout.Content>

      <Modal title={isEditing ? 'Edit Attribute' : 'Add Attribute'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Please input the label!' }]}>
            <Input />
          </Form.Item>
          <Form.List name="values">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      fieldKey={[fieldKey, 'label']}
                      rules={[{ required: true, message: 'Please input the label!' }]}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      fieldKey={[fieldKey, 'value']}
                      rules={[{ required: true, message: 'Please input the value!' }]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                    <Button type="danger" onClick={() => remove(name)}>
                      Delete
                    </Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Value
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item name="configs" label="Configs">
            <JsonConfigEditor />
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};

export default Attributes;
