import React, { useState } from 'react';
import { Layout, Typography, Form, Input, Button, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { AUTH } from 'Contexts/Mutations/Remote/Auth';
import { signIn } from 'Services/AuthService';
import './LoginStyle.css';

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [auth] = useMutation(AUTH, {
    onCompleted: ({ auth: data }) => {
      if (!data?.user?.admin) {
        setLoading(false);
        notification.error({ message: 'Ooops', description: 'Unauthorized' });
        return;
      }

      signIn(data.token, data.user);
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Ooops', description: error.message });

      setLoading(false);
    },
  });

  const doEmailAuth = async (data) => {
    await setLoading(true);

    auth({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  return (
    <Layout className="layout">
      <Layout.Content className="content">
        <div className="login-logo">
          <Typography.Title level={3}>Passsarim</Typography.Title>
        </div>
        <div className="login-logo">
          <Typography.Title level={2}>Login</Typography.Title>
        </div>
        <Form
          name="login"
          className="login-form"
          size="large"
          onFinish={doEmailAuth}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
          >
            <Input placeholder="E-mail" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading} block>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default Login;
