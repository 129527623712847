import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import './AppLayoutStyle.css';

const SideMenu = ({ currentUser, selectedMenu = 'dashboard' }) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const goTo = (location, state) => history.push(location, state);

  const menuStructure = [
    {
      parentKey: 'platformAdmin',
      childKeys: [
        'users',
        'verifyUsers',
      ],
    },
    {
      parentKey: 'objectsAdmin',
      childKeys: [
        'objects',
        'attributes',
      ],
    },
  ];

  const openSubMenu = menuStructure.find((sm) => sm.childKeys.includes(selectedMenu))?.parentKey || null;

  const menuItems = [
    {
      key: 'platformAdmin',
      label: 'Platform Admin',
      children: [
        {
          key: 'users',
          icon: <Icons.UserOutlined />,
          label: 'All Users',
          onClick: () => goTo('/users', { ref: 'users' }),
        },
        {
          key: 'verifyUsers',
          icon: <Icons.StarOutlined />,
          label: 'User Verification',
          onClick: () => goTo('/users/verify', { ref: 'verifyUsers' }),
        },
      ],
    },
    {
      key: 'objectsAdmin',
      label: 'Objects Admin',
      children: [
        {
          key: 'objects',
          icon: <Icons.BarsOutlined />,
          label: 'Objects',
          onClick: () => goTo('/objects'),
        },
        {
          key: 'attributes',
          icon: <Icons.BuildOutlined />,
          label: 'Attributes',
          onClick: () => goTo('/attributes'),
        },
      ],
    },
    {
      key: 'updateMe',
      icon: <Icons.IdcardOutlined />,
      label: 'My Profile',
      onClick: () => goTo(`/users/${currentUser.id}/me`, { ref: 'updateMe' }),
    },
  ];

  return (
    <Layout.Sider
      className="app-layout-side"
      theme="light"
      breakpoint="xs"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      collapsedWidth={0}
      zeroWidthTriggerStyle={{ color: 'white', backgroundColor: '#2f54eb', top: '86px' }}
      width={240}
    >
      <Menu
        className="app-layout-side-menu"
        mode="inline"
        defaultOpenKeys={[openSubMenu]}
        selectedKeys={selectedMenu && [selectedMenu]}
        items={menuItems}
      />
    </Layout.Sider>
  );
};

SideMenu.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedMenu: PropTypes.string,
};

export default SideMenu;
