import React from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';

const Map = ({ geolocations, onMapClick, onMarkerDragEnd, onMarkerRightClick }) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -19.9182, lng: -43.9366 }}
    onClick={onMapClick}
  >
    {geolocations.map((location, index) => (
      <Marker
        key={`i-${index}`} // eslint-disable-line
        position={{ lat: location.latitude, lng: location.longitude }}
        draggable
        onDragEnd={(event) => onMarkerDragEnd(event, index)}
        onRightClick={() => onMarkerRightClick(index)}
      />
    ))}
  </GoogleMap>
);

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default WrappedMap;
