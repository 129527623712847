import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Row, Col, Divider, Form, Input, Button, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_USER } from 'Contexts/Mutations/Remote/User';
import AppLayout from 'Components/AppLayout/AppLayout';
import './NewUserStyle.css';

const NewUser = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: (result) => {
      const user = result.createUser;
      history.push(`/users/${user.id}`);
      notification.success({ message: 'User created!' });
    },
    onError: (e) => {
      const error = (e.graphQLErrors && e.graphQLErrors[0]) || e;
      notification.error({ message: 'Ooops', description: error.message });

      setLoading(false);
    },
  });

  const onSubmit = async (data) => {
    await setLoading(true);
    await createUser({ variables: { data } });
  };

  return (
    <AppLayout selectedMenu="users">
      <PageHeader
        className="page-title"
        title="New User"
        onBack={() => history.push('/users')}
      />
      <Layout.Content className="new-product-content">
        <Form
          layout="horizontal"
          size="large"
          labelAlign="left"
          labelCol={{ md: { span: 3 }, sm: { span: 24 } }}
          wrapperCol={{ md: { span: 16 }, sm: { span: 24 } }}
          onFinish={onSubmit}
        >
          <Row>
            <Col xs={24}>
              <strong>User Information</strong><br /><br />
            </Col>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Provide a name for the user' },
                  { whitespace: true, message: 'Provide a proper name' },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="alias"
                label="Alias"
                rules={[
                  { required: true, message: 'Provide an alias for the user' },
                  { whitespace: true, message: 'Provide a proper alias' },
                ]}
              >
                <Input placeholder="Alias" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <strong>Access Details</strong><br /><br />
            </Col>
            <Col xs={24}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { required: true, message: 'Provide an email for user' },
                  { type: 'email', message: 'Inform a valid email' },
                ]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Provide a password for user' },
                  { whitespace: true, message: 'Provide a proper password' },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </AppLayout>
  );
};

export default NewUser;
